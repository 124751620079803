import React, { useState, startTransition, Suspense } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
// Component
import Modal from '../components/Modal';
// Layout
import Footer from './Footer';
import CookieBanner from './CookieBanner';
// imgs
import DelphiLogoBlack from '../public/img/LogoDelphi_Wien.webp';

function Nav() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    startTransition(() => {
      setIsModalOpen(true);
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Suspense fallback={'Loading..'}>
        <nav>
          <div className="inner-nav-container">
            <NavLink className="menu-link" to="speisekarte">
              menu
            </NavLink>
            <NavLink className="nav-logo" to="/">
              <img
                src={DelphiLogoBlack}
                alt="Delphi Logo"
                className="nav-logo-img"
              />
            </NavLink>
            <div className="nav-ellipse">
              <svg
                className="burger-menu"
                width="29"
                height="20"
                viewBox="0 0 31 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={openModal}
              >
                <path
                  d="M30.0834 19.2571C30.0834 20.0592 29.433 20.7082 28.6324 20.7082H16.9511C16.5663 20.7082 16.1972 20.5553 15.9251 20.2832C15.653 20.011 15.5001 19.642 15.5001 19.2571C15.5001 18.8723 15.653 18.5032 15.9251 18.2311C16.1972 17.959 16.5663 17.8061 16.9511 17.8061H28.6324C29.4345 17.8061 30.0834 18.455 30.0834 19.2571ZM30.0834 10.4998C30.0834 11.3019 29.433 11.9509 28.6324 11.9509H2.36779C1.98295 11.9509 1.61387 11.798 1.34175 11.5259C1.06963 11.2538 0.916748 10.8847 0.916748 10.4998C0.916748 10.115 1.06963 9.74592 1.34175 9.4738C1.61387 9.20167 1.98295 9.0488 2.36779 9.0488H28.6324C29.4345 9.0488 30.0834 9.69921 30.0834 10.4998ZM28.6324 3.19359C29.0172 3.19359 29.3863 3.04071 29.6584 2.76859C29.9305 2.49646 30.0834 2.12739 30.0834 1.74255C30.0834 1.35771 29.9305 0.988627 29.6584 0.716504C29.3863 0.444381 29.0172 0.291504 28.6324 0.291504H11.1178C10.9272 0.291504 10.7385 0.329036 10.5625 0.401958C10.3865 0.47488 10.2265 0.581762 10.0917 0.716504C9.95701 0.851246 9.85012 1.01121 9.7772 1.18726C9.70428 1.3633 9.66675 1.55199 9.66675 1.74255C9.66675 1.9331 9.70428 2.12179 9.7772 2.29784C9.85012 2.47388 9.95701 2.63385 10.0917 2.76859C10.2265 2.90333 10.3865 3.01021 10.5625 3.08313C10.7385 3.15605 10.9272 3.19359 11.1178 3.19359H28.6324Z"
                  fill="#5B3000"
                />
              </svg>
              <div className="modal-container">
                <Modal isOpen={isModalOpen} onClose={closeModal} />
              </div>
            </div>
          </div>
        </nav>
        <Outlet />
        <CookieBanner />
        <Footer />
      </Suspense>
    </>
  );
}

export default Nav;
